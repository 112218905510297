// Import Anthony Nolan Design System styles
@import '@an/design-system/src/styles/main';
@import '../styleguide/partials/grid';

html {
  min-height: 100%;
}

body {
  margin: 0;
  position: relative;
  background-color: $sand;
  min-height: 100vh;
  height: 100%;
}

.loader {
  border-width: 8px;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

main {
  margin: 0 auto 0;
  padding-bottom: 305px;
  @include mq(lg) {
    padding-bottom: 110px;
  }
}

header {
  background: $green;
  width: 100%;
  padding: 15px 20px;

  .grid {
    row-gap: 10px;
  }

  img {
    height: 40px;
    display: block;
    @include mq(lg) {
      height: 60px;
    }
  }

  .wrapper {
    max-width: 978px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
  }

  h1 {
    margin: 0;
    color: $green-dark;
    @include font-size(16);
    @media only screen and (min-width: 400px) {
      text-align: right;
    }
    @include mq(lg) {
      @include font-size(22);
    }
  }
}

.label {
  margin-bottom: 0.25em;
}

label.control--checkbox,
label.control--radio {
  margin: 10px 0;
  &:last-child {
    margin-bottom: 0;
  }
}

button {
  margin-right: 10px;
}

.indicator-row {
  display: table;
  width: 100%;
  margin: -0.5em 0 1em;
  div {
    display: table-cell;
    border-right: 1px solid $green-light-tint;
    height: 10px;
  }
  .page-complete {
    background-color: $green-light-tint;
  }
  .page-completing {
    background-color: $green-dark;
  }
  .page-incomplete {
    background-color: $green;
  }

  button {
    border: none;
    height: 10px;
    width: 100%;
    cursor: pointer;
  }
}

// Content wrapper
.content-wrapper {
  margin: 20px auto 0;
  max-width: 978px;

  button {
    display: flex;
    align-self: flex-start;
  }
}

p:first-child {
  margin-top: 0;
}

.grid {
  &--gap-40 {
    gap: 40px 1.5rem;
  }

  &--no-gap {
    gap: 0;
  }
  @include mq(lg) {
    gap: 1.5rem;
  }
}

a {
  color: $green-dark;
}

footer {
  background-color: $green-dark;
  color: $green-light-tint;
  padding: 5px 0;
  position: absolute;
  width: 100%;
  bottom: 0;

  ul {
    list-style: none;
    padding: 0;
    max-width: 978px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @include mq(lg) {
      flex-direction: row;
    }

    li {
      margin: 0.5em 0;
    }

    a {
      color: $green-light-tint;
      display: block;
      padding: 0.5rem 0;
      text-decoration: none;
      @include font-size(14);
      @include mq(lg) {
        padding: 0.5rem 1rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.content {
  position: relative;
}

.section {
  @include clearfix();
}

.btn:focus {
  outline: none;
}

.btn--link {
  display: inline;
  padding: 0;
  background: none;
  text-decoration: underline;
  text-transform: none;
  font-weight: normal;
  @include font-size(16);
  min-height: 0;
}

.loader--btn {
  position: static;
  float: right;
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin: 0 0 0 10px;
  border-color: $green-light-tint;
  border-top-color: $green;
}

.loader--relative {
  position: relative;
  margin-top: 0;
}

.btn--small .loader--btn {
  border-width: 2px;
  margin: 3px 0 0 10px;
}

.input-wrapper {
  position: relative;
  align-items: baseline;

  label {
    display: flex;
    align-items: center;
  }

  input[type='checkbox'],
  input[type='text'],
  input[type='radio'] {
    margin: 0;
    margin-right: 15px;
    vertical-align: middle;
  }

  select {
    margin: 0;
    margin-right: 10px;
    vertical-align: middle;
    width: 100%;
  }

  input[type='radio'],
  input[type='checkbox'] {
    & ~ label,
    & ~ span {
      @include font-size(16);

      @include mq(md) {
        @include font-size(18);
      }

      @include mq(lg) {
        @include font-size(20);
      }
    }
  }
}

.loader--input {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 28px;
  right: 10px;
  width: 10px;
  height: 10px;
  border-width: 3px;
  margin: 5px 0 0 10px;
}

// flexbox layouts
.flex-wrapper {
  @include mq(sm) {
    display: flex;
  }
}

.flex-item + .flex-item {
  @include mq(sm) {
    flex-grow: 1;
    margin-left: 10px;
  }
}

// Webforms-specific styles

.button-actions {
  display: flex;
}

input:disabled {
  color: $green;
}

input.error:disabled {
  color: $green;
}

button:disabled {
  background-color: $green-dark;
  opacity: 0.5;
}

.btn--invalid {
  opacity: 0.1;
  background-color: $green-dark;
}

.left-arrow {
  @extend .btn--secondary, .btn--arrow-left;
}
.right-arrow {
  @extend .btn--arrow-right;
}

// Remove arrows in date fields
input.date-field::-webkit-outer-spin-button,
input.date-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// Remove arrows in phone number fields
.phoneNumber input.number::-webkit-outer-spin-button,
.phoneNumber input.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.error-message {
  clear: both;
  display: block;
  font-style: normal;
  margin: 0;

  a {
    color: inherit;
  }

  p {
    @include font-size(14);
  }
}

fieldset {
  border: none;
  margin: 30px 0;
  padding: 0;
  &.header {
    margin-bottom: 0;
    p:last-child {
      margin-bottom: 0;
    }
  }
  & + fieldset.personalDetails {
    margin-top: 0;
  }
  .group {
    margin-bottom: 10px;
    &.checkbox {
      margin: 5px 0 0;
    }
    // Person Category
    &.supporterId {
      margin-bottom: 2em;
    }
  }
  &:not(:first-child) h2 {
    @extend h4;
    margin: 0 0 0.5em !important;
  }
  h2 {
    margin: 0 0 0.5em;
  }
  h3 {
    @extend h4;
    margin: 1em 0 0.5em;
  }
  fieldset:last-of-type {
    margin-bottom: 0;
  }
}

.description {
  margin: 0.5em 0;
}

// Pre Qualifiers
.preQualifiers {
  margin-bottom: 50px;

  h3 {
    margin-top: 0.5em;
    @include font-size(20);
    font-weight: 300;
    text-transform: none !important;
  }

  .input-wrapper {
    display: flex;
  }

  .error-box {
    background: none;
    border: 0;
    @include border-radius();
    padding: 0;

    .error-message {
      display: none;
    }
  }
}

.error-box {
  background-color: #efd1d1f7 !important;
}

// Personal Details
.personalDetails {
  .title {
    min-width: 100px;
  }

  .middleName {
    min-width: 250px;
  }
}

// Height & Weight
.heightWeight .control--small {
  display: inline-flex;
}

.height-indicator,
.weight-indicator {
  font-weight: 700;

  @include mq(sm) {
    line-height: 65px;
  }
}

.bmi-fail-wrapper,
.weight-fail-wrapper {
  display: none;
}

// Date of birth
.dateOfBirth {
  .label {
    color: $green-dark;
    font-weight: bold;
    @include font-size(14);
  }
}

.age-indicator {
  @include mq(sm) {
    margin-top: 12px;
    width: 48.468% !important;
  }
}

// Gender
.gender {
  margin: 2em 0 1em;
}

// Occupation
.military {
  margin-bottom: 1em;
}

// Ethnicity
.ethnicity {
  .label {
    color: $green-dark;
    font-weight: bold;
  }

  .description {
    margin: 0 0 1em;
  }

  label .selected {
    font-weight: 700;
  }

  .ethnicity-helpers {
    position: relative;
    padding-left: 30px;

    strong {
      display: block;
      margin: 0 0 0.5em;
    }

    .back {
      position: absolute;
      text-decoration: none;
      background-color: unset;
      border-bottom: none;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      vertical-align: middle;
      color: $green-dark;
      border-color: $green-dark;
      border-style: solid;
      border-width: 0 0 2px 2px;
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      top: 70px;
      margin-top: -58px;
      left: 5px;

      &::before {
        background-color: unset;
      }
    }
  }
}

// Address
.address-link {
  display: block;
  margin-top: 0.5em;
  @include mq(sm) {
    margin-top: 2.3em;
  }
  @include mq(md) {
    margin-top: 1.9em;
  }
}

.address-link-show {
  padding: 12px 0 22px;
  margin: 0;
}

// Medical history
.medicalHistory {
  h2 {
    @include font-size(20);
    color: $green-dark;
    text-transform: none !important;
  }
}

.blood_transfusion_wrapper {
  > div {
    clear: both;
  }
}

.content--fail {
  h2 {
    line-height: 1.2em;
    text-transform: none !important;
  }
}

.medical_conditions_wrapper {
  fieldset {
    margin: 0;
  }

  .medical-condition {
    padding: 10px;
    margin-bottom: 3px;
    background-color: $green-light-tint;

    .control--small {
      box-sizing: border-box;
      width: 100%;
    }

    .control--checkbox {
      margin: 0;
    }
  }

  .error-box {
    margin: 0 0 3px;
    @include border-radius();

    .medical-condition {
      margin: 0;
      padding: 0;
      background: none;
    }

    .error-message {
      margin: 0;
    }
  }
}

.condition-questions {
  margin-top: 15px;

  .span_12_of_12 {
    clear: both;
  }

  @include mq(lg) {
    .ongoing {
      float: right;

      div label:nth-child(2) {
        margin-right: 10px;
      }
    }
  }

  textarea {
    margin-bottom: 0;
  }
}

// Consent + GiftAid
.bordered,
.giftaid label {
  @include border-radius();
  border: 1px solid $green-dark;
  padding: 20px;
  width: 100%;
}

.giftaid input[type='checkbox'] {
  &:checked {
    background: $green-light-tint;
  }

  &:after {
    border-color: $green-dark;
  }
}

// Donations
.amount-wrapper {
  @include border-radius();
  border: 1px solid $green-dark;
  padding: 20px;
  margin-bottom: 20px;

  input.currency::-webkit-outer-spin-button,
  input.currency::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .giftaid {
    .label {
      text-align: center;
      margin: 1em 0;
    }

    label {
      box-sizing: border-box;
      width: 100%;
      background-image: url('../images/gift-aid.svg');
      background-size: 80px auto;
      background-repeat: no-repeat;
      background-position: right 20px top 10px;
      background-color: $green-dark;
      color: $green-light-tint;
      padding: 40px 10px;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: $green-dark;
      }

      @include mq(md) {
        background-size: 150px auto;
        background-position: right 20px center;
        padding: 20px 200px 20px 20px;
      }
    }

    .description {
      @include font-size(11);

      @include mq(md) {
        @include font-size(14);
      }
    }

    div.description {
      @include font-size(11);
    }

    input[type='checkbox']:checked + span {
      font-weight: normal;
    }
  }

  .giftaid-amount {
    text-align: center;
    font-weight: bold;
    margin-top: 0.5em;

    span {
      color: $green-dark;
    }
  }
}

.contact_details_wrapper {
  h2 {
    @include font-size(20);
    text-transform: none !important;
  }
}

fieldset.marketingPreferences {
  border: 1px solid $green-dark;
  @include border-radius;
  padding: 20px;
  margin: 0 0 30px;

  .group {
    margin: 10px 0;
  }

  h2 {
    @include font-size(20);
    color: $green-dark;
    margin-top: 0;
    text-transform: none !important;
  }

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .group:first-of-type {
    margin-top: 0;
  }

  label {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    margin: 0.5em 0;
    &:last-child {
      margin-bottom: 0.5em;
    }
  }

  .post {
    // Reverse checkbox for post opt-in
    input[type='checkbox']:checked {
      background-color: $sand;
    }
    input[type='checkbox']:not(:checked) {
      background-color: $green-dark;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.36641 9.00007L0.566406 5.20007L1.51641 4.25007L4.36641 7.10007L10.4831 0.983398L11.4331 1.9334L4.36641 9.00007Z' fill='%23F5FFF0'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;

    label {
      margin-right: 20px;
    }
  }

  small.description {
    margin: 0 0 0.5em;
    display: block;
  }
}

// Payment form
.payment-icons {
  background-color: $green-light-tint;
  box-sizing: border-box;
  border: 1px solid $green;
  margin-bottom: 20px;
  padding: 5px 10px;
  text-align: right;
  text-align: center;
  @include font-size(12);

  span,
  img {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
  }

  img {
    width: 63px;

    &:last-child {
      width: 80px;
    }
  }
}

.StripeElement {
  background-color: $green-light;
  border: 1px solid $green;
  margin: 0.5em 0;
  padding: 15px;
}

.StripeElement--focus {
  border: 1px solid $green;
}

.payment-error .StripeElement,
.StripeElement--invalid {
  background-color: #fae2e2;
  border: 1px solid #e36060;
  @include border-radius();
}

.error-box {
  @include border-radius();
  margin: 10px 0;
  padding: 20px;
  background-color: rgba(250, 226, 226, 0.5); // #fae2e2 with 50% opacity;

  h2 {
    margin: 0 0 0.5em;
    text-transform: none !important;
  }

  ul,
  li {
    margin: 0;
  }
}

.success-box {
  padding: 10px;
  border: 1px solid lighten($green-dark, 40%);
  background-color: lighten($green-dark, 60%);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;

  .popup {
    position: relative;
    width: 100%;
    max-width: 500px;
    background: $green-light-tint;
    padding: 30px;
    border: 1px solid $green;
    text-align: center;
    box-shadow: 0 0 20px $green-light-tint;
  }
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.description--small {
  margin-bottom: 20px;
  margin-top: 0;
  @include font-size(13);
}

.fieldset--no-margin {
  margin: 0 !important;
}

.payment-method-wrapper {
  border: 1px solid $green-light;
  margin-top: 20px;
  padding: 20px;

  h3 {
    margin-top: -30px;
    text-align: center;
  }

  span {
    background-color: $green-light-tint;
    display: inline-block;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.separator {
  border-top: 1px solid $green;
  color: $green-light;
  font-weight: normal;
  margin: 60px 0 40px;
  text-align: center;

  span {
    background-color: $green-light-tint;
    display: inline-block;
    padding: 0 20px;
    transform: translateY(-10px);
  }
}

// form label overrides

.float-label {
  position: relative;
  padding-top: 25px;

  label {
    position: absolute;
    top: 0;
    left: 0;
  }
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='number'] {
  &::placeholder {
    color: #666;
  }

  &:focus {
    background-color: $sand !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $sand inset !important;
  }
}

.email {
  .loader {
    position: absolute;
    top: 30px;
    width: 30px !important;
    height: 30px !important;
  }
}

// creating radio and checkbox styles

input[type='radio'] {
  appearance: none;
  width: 40px;
  height: 40px;
  border: 1px solid $green-dark;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  outline: none;

  &:checked {
    background: $green-dark;

    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $sand;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input[type='radio'].radio--large:checked {
    background-size: 16px;
  }
}

input[type='checkbox'] {
  appearance: none;
  width: 40px;
  height: 40px;
  border: 1px solid $green-dark;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
  outline: none;

  &:checked {
    background: $green-dark;

    &:after {
      content: '';
      position: absolute;
      width: 24px;
      height: 12px;
      border: solid $sand;
      border-width: 0 3px 3px 0;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg) scaleX(-1);
    }
  }
}

// Donate Your Umbilical Cord form
fieldset#disclaimers {
  margin-top: 0;
  h3:first-of-type {
    margin-top: 0;
  }
  .consent {
    margin-top: 40px;
  }
}
fieldset#yourPrivacy {
  margin-top: 0;
  h3:first-of-type {
    margin-top: 0;
  }
}

// Duke of Edinburgh Leader form
.howHeard small {
  margin-top: 0 !important;
}
fieldset#volunteerMarketingPreferences {
  margin-top: 0;
  h3 {
    margin-top: 0;
  }
}

// Volunteer Ambassador form
fieldset#genderGroup {
  margin-bottom: 0;
}
fieldset#ethnicityGroup,
fieldset#ethnicityGroup fieldset {
  margin-top: 0;
  margin-bottom: 0;
}
fieldset#education,
fieldset#education fieldset {
  margin-top: 0;
  margin-bottom: 0;
}
fieldset#howHeard {
  margin-bottom: 0;
}
fieldset#motivation {
  margin-top: 0;
  margin-bottom: 30px;
}
